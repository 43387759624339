<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems" />
    <hr v-chakra w="full" />
    <c-box mt="30px">
      <c-text color="brand.900" text-transform="uppercase">
        Detail Program
      </c-text>
      <c-text v-if="program" font-size="24px" font-weight="700">
        {{ program.name }}
      </c-text>
    </c-box>
    <c-box v-if="nutritionist" mt="30px">
      <c-text>Ahli Gizi Yang Dipilih</c-text>
      <hr />
      <c-flex
        mt="20px"
        p="20px"
        border="1px solid #F2F2F2"
        box-shadow="0px 5px 30px 0px #0000000D"
        rounded="md"
      >
        <c-image
          v-if="nutritionist"
          :src="nutritionist.photoUrl"
          object-fit="cover"
          rounded="full"
          h="96px"
          w="96px"
        />
        <c-box ml="20px">
          <c-stack is-inline spacing="20px">
            <c-text font-size="18px" font-weight="700">
              {{ nutritionist.firstName }} {{ nutritionist.lastName }}
              {{ nutritionist.title }}
            </c-text>
            <c-badge
              v-if="isAvailable"
              rounded="full"
              color="brand.900"
              variant-color="brand"
              variant="outline"
              border="1px solid"
              d="flex"
              align-items="center"
              justify-content="center"
              px="15px"
              text-transform="none"
              font-size="12px"
              font-weight="500"
            >
              Available
            </c-badge>
            <c-badge
              v-if="!isAvailable"
              rounded="full"
              d="flex"
              align-items="center"
              justify-content="center"
              bg="#EA43351A"
              border="1px solid #EA4335"
              w="fit-content"
              px="15px"
              variant="outline"
              variant-color="brand"
              color="#EA4335"
              text-transform="none"
              font-size="12px"
              font-weight="500"
            >
              Tidak Aktif
            </c-badge>
          </c-stack>
          <c-text font-size="16px" font-weight="400" color="brand.900">
            Nama Spesialiasi
          </c-text>
          <c-text
            font-size="14px"
            font-weight="400"
            line-height="21px"
            color="grey"
          >
            S1 &amp; Sekolah Profesi Universitas Brawijaya
          </c-text>
          <c-button
            mt="20px"
            bg="#F4CC46"
            color="#927102"
            rounded="full"
            :as="isAbleToChangeNutri ? 'router-link' : 'button'"
            :to="{ name: 'superadmin.clients-nutris' }"
            :is-disabled="!isAbleToChangeNutri"
            :disabled="!isAbleToChangeNutri"
          >
            <c-text>Ganti Ahli Gizi</c-text>
            <c-image
              :src="require('@/assets/icon-arrow-right-yellow.svg')"
              h="24px"
              w="24px"
              ml="5px"
            />
          </c-button>
        </c-box>
        <c-text ml="auto" fontSize="16px" color="gray.900">
          <c-image
            :src="require('@/assets/icon-star.svg')"
            alt="icon"
            display="inline-block"
            verticalAlign="middle"
            marginRight="0px"
          />
          {{ nutritionist.rating }}/5
        </c-text>
      </c-flex>
    </c-box>

    <c-flex mt="30px" grid-gap="15px" flex-wrap="wrap">
      <c-button
        type="button"
        h="94px"
        w="439px"
        bg="white"
        border="1px solid #F2F2F2"
        box-shadow="0px 5px 30px 0px #0000000D"
        rounded="md"
        justify-content="flex-start"
        grid-gap="20px"
        px="35px"
        as="router-link"
        :to="{ name: 'superadmin.clients-mealplan' }"
      >
        <c-image :src="require('@/assets/icon-menu-meat.svg')" size="32px" />
        <c-text>Meal Plan</c-text>
      </c-button>
      <c-button
        type="button"
        h="94px"
        w="439px"
        bg="white"
        border="1px solid #F2F2F2"
        box-shadow="0px 5px 30px 0px #0000000D"
        rounded="md"
        justify-content="flex-start"
        grid-gap="20px"
        px="35px"
        as="router-link"
        :to="{ name: 'superadmin.clients-followup-notes' }"
      >
        <c-image :src="require('@/assets/icon-menu-notes.svg')" size="32px" />
        <c-text>Follow Up Notes</c-text>
      </c-button>
      <c-button
        type="button"
        h="94px"
        w="439px"
        bg="white"
        border="1px solid #F2F2F2"
        box-shadow="0px 5px 30px 0px #0000000D"
        rounded="md"
        justify-content="flex-start"
        grid-gap="20px"
        px="35px"
        as="router-link"
        :to="{ name: 'superadmin.clients-progress' }"
      >
        <c-image
          :src="require('@/assets/ic_trending_active.svg')"
          size="32px"
        />
        <c-text>Progress and Tracker</c-text>
      </c-button>
      <c-button
        type="button"
        h="94px"
        w="439px"
        bg="white"
        border="1px solid #F2F2F2"
        box-shadow="0px 5px 30px 0px #0000000D"
        rounded="md"
        justify-content="flex-start"
        grid-gap="20px"
        px="35px"
        as="router-link"
        :to="{ name: 'superadmin.clients-quizionary' }"
      >
        <c-image
          :src="require('@/assets/ic_question-mark_active.svg')"
          size="32px"
        />
        <c-text>Kuisioner</c-text>
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumb.vue"
import dayjs from "dayjs"

export default {
  name: "SUClientsProgramIndex",
  components: { Breadcrumbs },
  data() {
    return {
      program: null,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    breadcrumbItems() {
      let clientId = this.clientId

      return [
        {
          label: "Manajemen Klien",
          href: this.$router.resolve({ name: "superadmin.clients" }).href,
        },
        {
          label: "Detail Klien",
          href: this.$router.resolve({
            name: "superadmin.clients-detail",
            params: { clientId },
          }).href,
        },
        { label: "Detail Program", href: null, isCurrent: true },
      ]
    },
    nutritionist() {
      let nutri = this.program?.nutritionist

      if (nutri != null) nutri.photoUrl ??= "https://via.placeholder.com/200"

      return nutri
    },
    isAbleToChangeNutri() {
      let endDate = dayjs(this.program.endAt)
      let diff = endDate.diff(new Date())
      return diff > 0
    },
    isAvailable() {
      let nClient = this.nutritionist?.nClient
      let quota = this.nutritionist?.quota
      return nClient < quota
    },
  },
  async mounted() {
    this.program = await this.$store.dispatch(
      "suManagementClient/getProgramById",
      this.programId
    )
  },
}
</script>
